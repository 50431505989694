<template>
  <div class="">
    <div class="">
      <div class="container mx-auto md:flex mb-10">
        <div class="md:w-1/2 p-28 md:pl-20 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
          >
            {{ $t("ext.download.firstTitle") }}<br />
            {{ $t("ext.download.secondTitle") }}
          </h2>
          <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
            {{ $t("ext.download.description") }}
          </p>
          <DownloadExt />
        </div>
        <div class="md:w-1/2 p-10 md:p-20">
          <img
            :src="require(`@/assets/images/ext-preview-${currentLocale}.png`)"
          />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 dark:bg-gray-800">
      <div class="container mx-auto md:flex mb-10">
        <div class="md:w-1/2 p-10 md:p-20 text-right">
          <img
            class="inline-block"
            :src="
              require(`@/assets/images/ext-memo-screen-${currentLocale}.png`)
            "
          />
        </div>
        <div class="md:w-1/2 pt-36 pr-28 md:pl-20 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              text-2xl
              tracking-tight
              text-webmoney
              dark:text-blue-400
              mb-4
              text-center
              flex
              items-center
              space-x-4
            "
          >
            <span class="">{{ $t("ext.memo.firstTitle") }}</span>
            <span class="w-28">
              <a href="https://memo.web.money/" target="_blank">
                <img src="@/assets/images/memo-logo.svg" />
              </a>
            </span>
          </h2>

          <p class="text-xl text-gray-500 dark:text-gray-400 mb-5">
            {{ $t("ext.memo.description1") }}
          </p>

          <p class="text-xl text-gray-500 dark:text-gray-400 mb-5">
            {{ $t("ext.memo.description2") }}
          </p>

          <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
            {{ $t("ext.memo.description3") }}
          </p>
        </div>
      </div>
    </div>

    <div class="pt-20" id="supportedBrowsers">
      <div class="container mx-auto">
        <h2
          class="
            font-bold
            uppercase
            tracking-tight
            text-2xl text-webmoney
            dark:text-blue-400
            mb-4
            text-center
          "
        >
          {{ $t("ext.browsers.supportedBrowsers") }}
        </h2>

        <div class="flex space-x-10 mx-auto justify-center my-20">
          <div class="" v-for="(extension, index) in extensions" :key="index">
            <a
              v-if="extension.link"
              :href="extension.link"
              target="_blank"
              class="
                p-5
                flex flex-col
                items-center
                hover:bg-gray-50 hover:text-webmoney
                dark:hover:bg-gray-800  dark:text-blue-400
                rounded
              "
            >
              <div class="mb-3"><img :src="extension.image" /></div>
              <div class="flex items-center">
                <span class="mr-1">{{ $t("ext.browsers.download") }}</span>
                <external-link-icon
                  size="1x"
                  class="text-gray-400"
                ></external-link-icon>
              </div>
            </a>

            <div v-else class="p-5 flex flex-col items-center">
              <div class="mb-3"><img :src="extension.image" /></div>
              <div class="text-sm text-gray-400">
                {{ $t("ext.browsers.comingSoon") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExternalLinkIcon } from "vue-feather-icons";
import { Trans } from "@/plugins/Translation";
import DownloadExt from "@/components/DownloadExt";

export default {
  components: {
    ExternalLinkIcon,
    DownloadExt
  },
  computed: {
    currentLocale() {
      return Trans.currentLocale;
    }
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      extensions: [
        {
          image: require("@/assets/images/chrome.svg"),
          link:
            "https://chrome.google.com/webstore/detail/webmoney-advisor/ecnpogknkjmbicoiindidldgedjibfep"
        },
        {
          image: require("@/assets/images/firefox.svg"),
          link: null
        },
        {
          image: require("@/assets/images/edge.svg"),
          link:
            "https://microsoftedge.microsoft.com/addons/detail/onnoahhkfhimhbbdmilbamdhldhppdda"
        },
        { image: require("@/assets/images/opera.svg"), link: null }
      ]
    };
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => (location.href = this.$route.hash), 1);
    }
  },
  methods: {}
};
</script>
